var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"dropdown",class:_vm.$style.container},[_c('span',{class:_vm.$style.arrow}),_c('div',{class:_vm.$style.header},[_c('h2',[_vm._v(_vm._s(_vm.title))])]),_c('div',{class:_vm.$style.filters},[_c('div',{class:_vm.$style.filter},[_c('div',{class:_vm.$style.checkbox,on:{"click":function($event){return _vm.$emit('input', { ..._vm.value, isActive: !_vm.value.isActive })}}},[_c('span',{class:[
            _vm.$style.checkmark,
            { [_vm.$style.activeCheckmark]: _vm.value.isActive }
          ]}),_c('span',{class:_vm.$style.label},[_vm._v("Активный")]),_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.value.isActive}})])]),_c('div',{class:_vm.$style.filter},[_c('div',{class:_vm.$style.checkbox,on:{"click":function($event){return _vm.$emit('input', { ..._vm.value, isAvailable: !_vm.value.isAvailable })}}},[_c('span',{class:[
            _vm.$style.checkmark,
            { [_vm.$style.activeCheckmark]: _vm.value.isAvailable }
          ]}),_c('span',{class:_vm.$style.label},[_vm._v("В наличии")]),_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.value.isAvailable}})])]),_c('div',{class:_vm.$style.filter},[_c('div',{class:_vm.$style.checkbox,on:{"click":function($event){return _vm.$emit('input', { ..._vm.value, havePresents: !_vm.value.havePresents })}}},[_c('span',{class:[
            _vm.$style.checkmark,
            { [_vm.$style.activeCheckmark]: _vm.value.havePresents }
          ]}),_c('span',{class:_vm.$style.label},[_vm._v("Подарок")]),_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.value.havePresents}})])]),_c('div',{class:_vm.$style.filter},[_c('div',{class:_vm.$style.checkbox,on:{"click":function($event){return _vm.$emit('input', {
            ..._vm.value,
            haveExpressDelivery: !_vm.value.haveExpressDelivery
          })}}},[_c('span',{class:[
            _vm.$style.checkmark,
            { [_vm.$style.activeCheckmark]: _vm.value.haveExpressDelivery }
          ]}),_c('span',{class:_vm.$style.label},[_vm._v("Экспресс-доставка")]),_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.value.haveExpressDelivery}})])]),_c('div',{class:[_vm.$style.filter, _vm.$style.select]},[_c('p',[_vm._v("Бренды:")]),_c('el-select',{attrs:{"value":_vm.value.brands,"multiple":"","filterable":"","placeholder":"Выбрать","collapse-tags":"","clearable":""},on:{"change":function($event){return _vm.$emit('input', { ..._vm.value, brands: $event })}}},_vm._l((_vm.options.brands),function(brand){return _c('el-option',{key:brand.id,attrs:{"label":brand.name,"value":brand.slug}},[_c('span',[_vm._v(_vm._s(brand.name))])])}),1)],1),_c('div',{class:[_vm.$style.filter, _vm.$style.select]},[_c('p',[_vm._v("Категории:")]),_c('el-cascader',{attrs:{"value":_vm.value.categories,"options":_vm.options.categories,"props":{
          label: 'name',
          value: 'slug',
          multiple: true,
          emitPath: false,
          checkStrictly: true
        },"collapse-tags":"","clearable":"","filterable":""},on:{"change":function($event){return _vm.$emit('input', { ..._vm.value, categories: $event })}}})],1),_c('div',{class:[_vm.$style.filter, _vm.$style.select]},[_c('p',[_vm._v("Ассортименты:")]),_c('el-select',{attrs:{"value":_vm.value.assortments,"multiple":"","filterable":"","placeholder":"Выбрать","collapse-tags":"","clearable":""},on:{"change":function($event){return _vm.$emit('input', { ..._vm.value, assortments: $event })}}},_vm._l((_vm.options.assortments),function(assortment){return _c('el-option',{key:assortment.id,attrs:{"label":assortment.name,"value":assortment.slug}},[_c('span',[_vm._v(_vm._s(assortment.name))])])}),1)],1),_c('div',{class:[_vm.$style.filter, _vm.$style.select]},[_c('p',[_vm._v("Поставщик:")]),_c('el-select',{directives:[{name:"el-select-loadmore",rawName:"v-el-select-loadmore",value:(_vm.loadMoreSuppliers),expression:"loadMoreSuppliers"}],attrs:{"value":_vm.value.supplier,"filterable":"","placeholder":"Выбрать","clearable":"","remote":"","remote-method":_vm.findSupplier},on:{"change":function($event){return _vm.$emit('input', { ..._vm.value, supplier: $event })},"clear":_vm.clearSupplier}},_vm._l((_vm.options.suppliers.data),function(supplier){return _c('el-option',{key:supplier,attrs:{"label":supplier,"value":supplier}},[_c('span',[_vm._v(_vm._s(supplier))])])}),1)],1),_c('div',{class:_vm.$style.actions},[_c('el-button',{class:_vm.$style.button,on:{"click":function($event){return _vm.$emit('applyFilters')}}},[_vm._v("Применить")]),_c('el-button',{class:_vm.$style.button,on:{"click":function($event){return _vm.$emit('resetFilters')}}},[_vm._v("Сбросить")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }