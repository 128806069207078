<template>
  <div :class="$style.container" ref="dropdown">
    <span :class="$style.arrow"></span>
    <div :class="$style.header">
      <h2>{{ title }}</h2>
    </div>
    <div :class="$style.filters">
      <div :class="$style.filter">
        <div
          :class="$style.checkbox"
          @click="$emit('input', { ...value, isActive: !value.isActive })"
        >
          <span
            :class="[
              $style.checkmark,
              { [$style.activeCheckmark]: value.isActive }
            ]"
          ></span>
          <span :class="$style.label">Активный</span>
          <input type="checkbox" :checked="value.isActive" />
        </div>
      </div>
      <div :class="$style.filter">
        <div
          :class="$style.checkbox"
          @click="$emit('input', { ...value, isAvailable: !value.isAvailable })"
        >
          <span
            :class="[
              $style.checkmark,
              { [$style.activeCheckmark]: value.isAvailable }
            ]"
          ></span>
          <span :class="$style.label">В наличии</span>
          <input type="checkbox" :checked="value.isAvailable" />
        </div>
      </div>
      <div :class="$style.filter">
        <div
          :class="$style.checkbox"
          @click="
            $emit('input', { ...value, havePresents: !value.havePresents })
          "
        >
          <span
            :class="[
              $style.checkmark,
              { [$style.activeCheckmark]: value.havePresents }
            ]"
          ></span>
          <span :class="$style.label">Подарок</span>
          <input type="checkbox" :checked="value.havePresents" />
        </div>
      </div>
      <div :class="$style.filter">
        <div
          :class="$style.checkbox"
          @click="
            $emit('input', {
              ...value,
              haveExpressDelivery: !value.haveExpressDelivery
            })
          "
        >
          <span
            :class="[
              $style.checkmark,
              { [$style.activeCheckmark]: value.haveExpressDelivery }
            ]"
          ></span>
          <span :class="$style.label">Экспресс-доставка</span>
          <input type="checkbox" :checked="value.haveExpressDelivery" />
        </div>
      </div>
      <div :class="[$style.filter, $style.select]">
        <p>Бренды:</p>
        <el-select
          :value="value.brands"
          multiple
          filterable
          placeholder="Выбрать"
          collapse-tags
          clearable
          @change="$emit('input', { ...value, brands: $event })"
        >
          <el-option
            v-for="brand in options.brands"
            :key="brand.id"
            :label="brand.name"
            :value="brand.slug"
          >
            <span>{{ brand.name }}</span>
          </el-option>
        </el-select>
      </div>
      <div :class="[$style.filter, $style.select]">
        <p>Категории:</p>
        <el-cascader
          :value="value.categories"
          :options="options.categories"
          :props="{
            label: 'name',
            value: 'slug',
            multiple: true,
            emitPath: false,
            checkStrictly: true
          }"
          collapse-tags
          clearable
          filterable
          @change="$emit('input', { ...value, categories: $event })"
        />
      </div>
      <div :class="[$style.filter, $style.select]">
        <p>Ассортименты:</p>
        <el-select
          :value="value.assortments"
          multiple
          filterable
          placeholder="Выбрать"
          collapse-tags
          clearable
          @change="$emit('input', { ...value, assortments: $event })"
        >
          <el-option
            v-for="assortment in options.assortments"
            :key="assortment.id"
            :label="assortment.name"
            :value="assortment.slug"
          >
            <span>{{ assortment.name }}</span>
          </el-option>
        </el-select>
      </div>
      <div :class="[$style.filter, $style.select]">
        <p>Поставщик:</p>
        <el-select
          :value="value.supplier"
          filterable
          placeholder="Выбрать"
          clearable
          remote
          :remote-method="findSupplier"
          v-el-select-loadmore="loadMoreSuppliers"
          @change="$emit('input', { ...value, supplier: $event })"
          @clear="clearSupplier"
        >
          <el-option
            v-for="supplier in options.suppliers.data"
            :key="supplier"
            :label="supplier"
            :value="supplier"
          >
            <span>{{ supplier }}</span>
          </el-option>
        </el-select>
      </div>
      <div :class="$style.actions">
        <el-button :class="$style.button" @click="$emit('applyFilters')"
          >Применить</el-button
        >
        <el-button :class="$style.button" @click="$emit('resetFilters')"
          >Сбросить</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => document.addEventListener('click', this.handleClick), 0)
  },
  destroyed() {
    document.removeEventListener('click', this.handleClick)
  },
  props: {
    isOpen: Boolean,
    title: String,
    options: {
      type: Object,
      default: () => ({
        brands: [],
        categories: [],
        assortments: [],
        isAvailable: false,
        havePresents: false,
        haveExpressDelivery: false,
        isActive: false,
        supplier: '',
        suppliersTotal: 0
      })
    },
    value: {
      type: Object,
      default: () => ({
        brands: [],
        categories: [],
        assortments: [],
        isAvailable: false,
        havePresents: false,
        haveExpressDelivery: false,
        isActive: false
      })
    }
  },
  methods: {
    clearSupplier() {
      this.$emit('clearSupplier')
    },

    handleClick(event) {
      if (!this.$refs.dropdown.contains(event.target) && this.isOpen) {
        this.$emit('close')
      }
    },
    findSupplier(name) {
      this.$emit('findSupplier', name)
    },
    loadMoreSuppliers() {
      this.$emit('loadMoreSuppliers')
    }
  }
}
</script>

<style lang="scss" module>
.container {
  padding-top: 0.75rem;
  position: absolute;
  left: 1rem;
  top: 3.55rem;
  background-color: $light-gray;
  color: $black;
  z-index: 10;
  width: 25rem;
  border-radius: 0.25rem;
  .header {
    text-align: center;
    h2 {
      color: $black;
    }
  }
  .arrow {
    width: 1rem;
    height: 1rem;
    position: absolute;
    background-color: $light-gray;
    transform: rotate(45deg);
    top: -0.4rem;
    left: 5%;
    z-index: 20;
  }
  .filters {
    padding: 1rem;
    .actions {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
    }
    .filter {
      margin-top: 0.5rem;
      .checkbox {
        position: relative;
        display: inline-block;
        input {
          position: absolute;
          opacity: 0;
          cursor: default;
          height: 0;
          width: 0;
        }
        .checkmark {
          position: absolute;
          top: 3px;
          left: 0;
          height: 16px;
          width: 16px;
          border-radius: 2px;
          border: 2px solid grey;
          background-color: #fff;
          &::after {
            left: 6.4px;
            top: 2px;
            width: 3px;
            height: 9px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            content: '';
            position: absolute;
          }
          &:hover {
            border-color: $black;
          }
        }
        .activeCheckmark {
          background-color: $black;
          border: 0;
        }
        .label {
          margin-left: 1.5rem;
        }
      }
    }
  }
  .select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin-right: 1rem;
    }
  }
}
</style>
